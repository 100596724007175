import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import BundleContainer from 'components/ProductKits';

const propTypes = {
  data: PropTypes.object.isRequired,
};


class BundlePageTemplate extends React.Component {

  render() {
    const { data, location } = this.props;
    return (
      <Layout location={location} type="standard">
        <SEO
          title={data.bundlePagesYaml.title}
          meta={data.bundlePagesYaml.metaTags}
          titleTemplate="%s"
        />
        <BundleContainer data={data} />
        <ZipGateContainer location={location}/>
      </Layout>
    );
  }
}
BundlePageTemplate.propTypes = propTypes;

export default BundlePageTemplate;

export const pageQuery = graphql`
  query BundlePagesQuery($id: String!) {
    bundlePagesYaml(id: {eq: $id}) {
      id
      path
      title
      productId
      metaTags {
        name
        content
      }
      bundle {
        new
        button {
          text
          url
        }
      }
      bigInfoCards {
        color
        type
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 891, maxHeight: 521) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      iconsBlock {
        icons {
          text
          icon
        }
      }
      callUs
      bundleHowItWorks {
        items {
          image {
            publicURL
          }
          preTitle
          title
          description
        }
      }
      infoBlock {
        title
        infoCards {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 535, maxHeight: 405) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      terms {
        title
        text
        closeButton
      }
      disclaimer {
        plansText
        plansButton
        planId
        termsPrefix
        termsButton
        termsPostfix
      }
    }
  }
`
